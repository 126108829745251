<template>
  <a-modal
    :title="handle === 'add' ? '新增' : handle === 'edit' ? '编辑' : '查看'"
    style="top: 8px"
    width="80%"
    v-model="visible"
    :footer="null"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 21 }"
    >
      <a-row>
        <a-col :md="24">
          <a-form-model-item label="上级分类">
            <a-cascader
              :disabled="handle == 'edit' || handle == 'check'"
              v-model="value"
              placeholder="请选择"
              :options="categoryList"
              @change="superiorChange"
              change-on-select
              :field-names="{ label: 'name', value: 'id', children: 'children' }"
            >
            </a-cascader>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24">
          <a-form-model-item label="分类名称" prop="name">
            <a-input :disabled="handle == 'check'" v-model="rowData.name" placeholder="输入分类名称"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24">
          <a-form-model-item label="分类编码" prop="categoryCode">
            <a-input
              :disabled="(handle == 'edit' && !flagCodeEnable) || handle == 'check'"
              v-model="rowData.categoryCode"
              placeholder="输入分类编码"
            ></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-col :md="24">
        <a-form-model-item label="三菱产品" prop="flagMitsubishi">
          <a-radio-group :options="flagMitsubishiOptions" v-model="rowData.flagMitsubishi" :disabled="flagMitsubishiDisabled"/>
        </a-form-model-item>
      </a-col>
      <a-row>
        <a-col :md="24">
          <a-form-model-item label="排序" prop="">
            <a-input :disabled="handle == 'check'" v-model="rowData.sort" placeholder="请输入排序"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24">
          <a-form-model-item label="状态" prop="flagEnable">
            <a-switch
              :disabled="handle == 'check'"
              v-model="rowData.flagEnable"
              checked-children="启用"
              un-checked-children="禁用"
              default-checked
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24">
          <a-form-model-item label="分类图标" prop="(parentId">
            <QiniuUpload
              :disabled="handle == 'check'"
              type="image"
              :size="9"
              :value.sync="rowData.imgUrl"
            ></QiniuUpload>
            <div class="upload-hint-text">支持扩展名：.jpg .png</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24">
          <a-form-model-item label="分类说明" prop="(remark" :labelCol="{ span: 3 }" :wrapperCol="{ span: 21 }">
            <a-textarea :disabled="handle == 'check'" v-model="rowData.remark" :rows="5" placeholder="请输入描述说明" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="footer-bts" v-if="handle != 'check'">
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="toSubmit">提交</a-button>
    </div>
  </a-modal>
</template>

<script>
import { editProductCategory, selectByIdProductCategory, addProductCategory } from '../api/ProductCategoryApi'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  data() {
    return {
      visible: false,
      value: [],
      flagMitsubishiOptions: [
        {
          label: '三菱产品',
          value: 1,
        },
        {
          label: '非三菱产品',
          value: 2,
        },
        {
          label: '三菱&非三菱',
          value: 3,
        },
      ],

      handle: 'add',
      rowData: {},
      flagCodeEnable: false,
      loading: false,
      categoryList: [],
      imageUrl: '',
      flagMitsubishiDisabled:false,
      // 表单验证
      formRule: {
        flagMitsubishi: [{ required: true, message: '是否三菱产品', trigger: 'change' }],
        name: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
        categoryCode: [
          { pattern: new RegExp(/^[a-zA-Z\d]+$/), message: '分类编码仅限输入字母或者数字', trigger: 'blur' },
        ],
        remark: [{ required: true, message: '请输入分类说明', trigger: 'blur' }],
        parentNames: [{ required: true, message: '请输入父类目名称串', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
      },
    }
  },
  methods: {
    // 禁用弹框
    handleCancel() {
      this.rowData = {}
      this.value = []
      this.visible = false
    },
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.rowData = {}
      this.value = []
      this.handle = handle
      this.visible = true
      this.rowData = row
      this.flagCodeEnable = false
      console.log(this.rowData)
      if (this.rowData.parentIds != undefined) {
        let item = this.rowData.parentIds.split('_').map(Number)
        this.value = item
        console.log(this.value)
      } else {
        this.value = [0]
      }
      if (handle == 'add') {
        this.$set(this.rowData, 'flagEnable', true)
      }

      if (handle == 'edit') {
        this.axios.get('/api/product/category/getProductCountById/' + row.id).then((res) => {
          if (res.body) {
            this.flagCodeEnable = true
          }
        })
      }

      this.axios.get('/api/product/category/list/all?flagEnable=true').then((res) => {
        this.categoryList = res.body[0].children
        let item = {
          id: 0,
          name: '无',
        }
        this.categoryList.unshift(item)
      })
    },
    /**
     * 表单提交
     */
    toSubmit() {
      // let item = {
      //     id: Number(this.value.pop().toString())
      //   }
      //   this.rowData.parentCategory = item
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        let item = {
          id: Number(this.value.pop()),
        }
        this.rowData.parentCategory = item
        const res =
          this.handle === 'add' ? await addProductCategory(this.rowData) : await editProductCategory(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.visible = false
          this.$notification.error({ message: res.message })
        }
      })
    },
    superiorChange(value) {
      this.value = value
      let id = value[0]
      if(id != 0){
      this.axios.get(`/api/product/category/find/${id}`).then((res) => {
        this.$set(this.rowData,'flagMitsubishi',res.body.flagMitsubishi)
           this.flagMitsubishiDisabled = true
      })
      }else{
         this.flagMitsubishiDisabled = false
      }
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>
